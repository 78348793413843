.sd-item--disabled {
  .sd-item__control-label {
    opacity: 1;
  }
}

.correctAnswer {
  color: green;
}

.incorrectAnswer {
  color: red;
}

.sd-timer.sd-timer--top {
  position: absolute !important;
  top: -24px;
  right: 0;
}
