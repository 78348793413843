html {
  font-size: var(--font-size) !important;
}

body {
  font-family: 'Poppins', sans-serif !important;
}

:root {
  --sidebarWidth: 345px;
  --miniSidebarWidth: 60px;
}

$primary: #3C5EAB;
$secondary: #31B1EA;
$success: #3CA7AB;
$warning: #F4C546;
$danger: #FF384F;

$body-bg: #F7F7F7;
$input-bg: #FFFFFF;
$table-hover-bg: #fff;
$table-cell-padding-y: 15px;
$form-select-line-height: 28px;
$form-label-font-size: 1.125rem;
$form-label-font-weight: 500;
$input-line-height: 2.125;
$input-border-radius: 5px;
$input-border-color: #E4E4E4;
$input-focus-border-color: #333333;
$modal-content-bg: #F5F6FD;
$modal-content-border-radius: 12px;
$btn-border-radius: 45px;
$enable-negative-margins: true;
$card-border-color: #F5F6FD;
$box-shadow: 0px 4px 8px #C7D0E9;
$font-family-code: 'Poppins', sans-serif;

@media (max-width:1199px) {
  html {
    font-size: calc(var(--font-size) - 1);
  }
}

@media (max-width:767.90px) {
  html {
    font-size: calc(var(--font-size) - 2);
  }
}

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

$custom-colors: (
  "primary-light": #E1E9FC,
  "primary-lighter": #F5F6FD
);

/* Global CSS Start */
.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.btn:not([class*='btn-outline-']):active,
.btn:not([class*='btn-outline-']):active:focus-visible,
.btn:not([class*='btn-outline-']):focus,
.btn-close:focus {
  border-color: transparent !important;
  outline: none;
  box-shadow: none;
}

.form-control:focus,
.form-select:focus {
  box-shadow: none;
}

.font-16 {
  font-size: 1rem;
}

.font-18 {
  font-size: 1.125rem;
}

.font-20 {
  font-size: 1.25rem;
}

.font-22 {
  font-size: 1.375rem;
}

.font-24 {
  font-size: 1.5rem;
}

.font-26 {
  font-size: 1.625rem;
}

.font-34 {
  font-size: 2.125rem;
}

.fw-medium {
  font-weight: 500;
}

.mw-120 {
  min-width: 120px;
}

.mw-200 {
  min-width: 200px;
}

.size-30 {
  height: 30px;
  width: 30px;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.size-38 {
  height: 38px;
  width: 38px;
  padding: 0 !important;
}

.size-32-sm {
  @include media-breakpoint-down(sm) {
    height: 32px;
    width: 32px;
    padding: 0.25rem !important;
  }
}

.size-50 {
  height: 50px;
  width: 50px;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-filter {
  position: relative;
  box-shadow: 0px 1px 2px #00000029;
}

.search-filter .icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 13px;
}

.sticky-top {
  z-index: 998 !important;
}

.modal-dialog {
  max-width: 580px !important;
  width: 100% !important;
}

@media only screen and (max-width: 600px) {
  .modal-dialog {
    width: auto !important;
  }
}

textarea {
  resize: none;
}

.table thead tr th {
  white-space: nowrap;
}

/* Global CSS End */


/* TopBar CSS Start */

#top-navbar .dropdown-toggle::after {
  display: none;
}

.logo {
  background: $primary;
  width: var(--sidebarWidth);
  height: 70px;
}

.logo img {
  max-width: 45px;
  max-height: 45px;
}

.guest-login-img {
  max-width: 600px;
  width: 85%;
}

/* TopBar CSS End */

/* LeftNavigation CSS Start */

.mini-sidebar #side-menu .nav-link-label {
  display: none;
}

#side-menu {
  position: fixed;
  left: 0px;
  top: 70px;
  z-index: 999;
  width: 100%;
  padding: 0;
  min-width: var(--sidebarWidth);
  max-width: var(--sidebarWidth);
  height: calc(100vh - 70px);
  z-index: 999;
  overflow: auto;
}

.content-wrapper {
  margin-left: var(--sidebarWidth);
  position: relative;
}

@media (min-width: 992px) {
  .mini-sidebar #side-menu {
    min-width: var(--miniSidebarWidth);
    max-width: var(--miniSidebarWidth);
  }

  .mini-sidebar .logo,
  .mini-logo.logo {
    min-width: var(--miniSidebarWidth);
    max-width: var(--miniSidebarWidth);
  }

  .mini-sidebar .content-wrapper {
    margin-left: var(--miniSidebarWidth);
  }
}

@media (max-width:991.98px) {
  #side-menu {
    left: calc(var(--sidebarWidth) * -1)
  }

  .logo {
    min-width: var(--miniSidebarWidth);
    max-width: var(--miniSidebarWidth);
    background: #fff;
  }

  .mini-sidebar #side-menu {
    min-width: var(--sidebarWidth);
    max-width: var(--sidebarWidth);
    left: 0;
    overflow: auto;
  }

  .mini-sidebar #side-menu .nav-link-label {
    display: block;
  }

  .content-wrapper {
    margin-left: 0;
  }
}

#side-menu .nav-link {
  color: #fff;
  font-size: 1.25rem;
  padding: 12px;
}

#create-meeting .modal-content {
  box-shadow: 0px 4px 8px #C7D0E9;
  border: 1px solid #F5F6FD;
}

/* LeftNavigation CSS End */

/* Dashboard Page CSS Start */

.meeting-card .card {
  width: calc(50% - 1rem);
  border-radius: 45px;
}

@media (min-width:1400px) {
  .meeting-card .card {
    width: 310px;
  }
}

.meeting-card img {
  max-width: 70px;
  object-fit: contain;
}

.meeting-card .count {
  font-size: 2.5rem;
  margin-bottom: 5px;
}

/* Dashboard Page CSS End */

/* My Meeting Page CSS Start */

.custom-tab {
  .nav {
    border: 1px solid $primary;
    border-radius: 15px 15px 0 0;
    width: 100%;
    overflow: hidden;
  }

  .nav-item {
    border-radius: 0;
    flex: 1;

    &:first-child .nav-link {
      border-top-left-radius: 15px;
    }

    &:last-child .nav-link {
      border-top-right-radius: 15px;
    }

    &+.nav-item {
      border-left: 1px solid $primary;
    }

    .nav-link {
      width: 100%;
      border-radius: 0 !important;
    }
  }
}

.c-p {
  cursor: pointer;
}

.white-space-pre {
  white-space: pre;
}

/* My Meeting Page CSS End */

/* Join Meeting Page CSS Start */

.sponsor-detail {
  border-radius: 12px;
}

.company-sponsor {
  display: flex;
  align-items: center;
  height: 80px;
  width: 80px;
  border: 2px solid #e3e3e4;
  border-radius: 50%;
  object-fit: contain;
}

.section-tab {
  border-radius: 0 0 12px 12px !important;
  overflow: hidden;
}

.tab-content {

  &.meeting {
    .card {
      border-radius: 12px;
      box-shadow: 0px 4px 8px #C7D0E9;
    }

    .row [class*='col-']:first-child .card {
      border-radius: 0 12px 12px 12px;
    }
  }
}

.conversation-list {
  height: calc(100vh - 324px) !important;
  overflow: auto;
  padding: 4px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1 1 auto;
}

.polls-card,
.quiz-card {
  height: calc(100vh - 238px) !important;
  overflow: auto;
  background-color: rgb(248, 249, 250);
  border-radius: 6px;
  border: none;
}

.user-type {
  width: 180px;
}

@media (max-width:767px) {
  .custom-tab .nav-link {
    padding: 10px 8px;
  }
}

/* Join Meeting Page CSS End */

/* Poll Page CSS Start */

.badge {
  background-color: $primary;
  color: #fff;
}

.question-block {
  background-color: #E8E8E8;
  box-shadow: 0px 4px 8px #C7D0E9;
  border-radius: 12px;
}

.poll-table .table th,
td {
  vertical-align: middle;
}

.poll-table .table tr:hover td .badge {
  background-color: #E1E9FC;
  color: $primary;
}

.poll-table .table {
  min-width: 500px;
}

.poll-table .table tr td.action-td {
  width: 130px;
}

/* Poll Page CSS End */

/* Poll Detail Page CSS Start */


.question-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .question-item {
    border: 1px solid #ddd !important;
    border-radius: 5px;
    background-color: #FFFFFF;
    overflow: hidden;
    position: relative;

    .btn-remove-question {
      position: absolute;
      top: 10px;
      right: 70px;
      z-index: 4;
    }
  }
}

.question-title {
  padding: 8px 8px 8px 15px;
}

.question-option {
  padding: 0px;
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  gap: 15px;
  counter-reset: item;
  margin-bottom: 5px;

  li {
    position: relative;
    display: flex;
    align-items: center;
    min-width: calc(50% - 10px);

    &::before {
      content: counter(item, upper-alpha);
      counter-increment: item;
      width: 30px;
      height: 30px;
      margin-right: 5px;
      color: #2d8655;
      border: 2px solid #2d8655;
      font-size: 0.75rem;
      border-radius: 50%;
      display: flex;
      font-weight: bold;
      align-items: center;
      justify-content: center;
    }

    &.correct-option {
      &::before {
        background: #198754;
        color: #fff;
      }
    }
  }
}

.option-wrapper {
  padding: 15px;
}

.flex-fill {
  min-width: 5px;
}

.question-block .option-wrapper {
  display: none;
}

.question-block .is-active .option-wrapper {
  display: block;
}

.question-block .is-active .fa-arrow-circle-down::before {
  content: "\f0aa";
}

.btn-copy {
  max-width: 120px;
}

/* Poll Detail Page CSS End */
.feedback-wrapper {
  padding-top: 100px;
  height: calc(100vh - 64px);
  overflow: auto;
}

@media(max-width:536px) {
  .meeting-card .count {
    font-size: 1.25rem;
  }

  .font-20 {
    font-size: 0.775rem;
  }

  .font-profile {
    font-size: 1.2rem;
  }

  .meeting-card img {
    max-width: 40px;
  }
}

.star-rating button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.star-rating .on {
  color: #455da9;
}

.star-rating .off {
  color: #ccc;
}

.btn-end-meeting {
  position: fixed;
  z-index: 1000;
  top: 16px;

  .btn {
    @include media-breakpoint-down(md) {
      width: 36px;
      height: 36px;
      padding: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.header-action-btns {
  .btn {
    @include media-breakpoint-down(md) {
      width: 36px;
      height: 36px;
      padding: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

#top-navbar .dropdown-menu.show {
  z-index: 1002;
}

.validation-absolute {
  position: absolute;
  bottom: 0;
  left: 5px;
}

pre {
  white-space: pre-wrap;
}

.scroll-bottom {
  position: absolute;
  right: 2vw;
  bottom: 110px;
  border: 1px solid #455da9 !important;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 14px;
  color: #455da9 !important;
  background: #fff;
  z-index: 9;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

textarea {
  height: 80px;
}

@font-face {
  font-family: 'password';
  font-style: normal;
  font-weight: 400;
  src: url(https://jsbin-user-assets.s3.amazonaws.com/rafaelcastrocouto/password.ttf);
}

input.password-text {
  font-family: 'password';
}

input.password-text::-webkit-input-placeholder {
  font-family: 'Poppins', sans-serif
}

.near-meeting-listing {
  list-style: none;
  padding: 0;
  margin: 0;
}

.near-meeting-listing li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.near-meeting-listing li .col-action {
  min-width: 110px;
}

.img-sponsor {
  width: 80% !important;
  height: 60% !important;
  padding-right: 5px !important;
}

.password {
  padding-right: 2.5em;
}

.btn-blue {
  background-color: $primary;
  border-color: $primary;
  padding: 4px 10px !important;
  width: auto;
}

span.meeting-code-tag {
  cursor: pointer;
  background-color: #1e2f55;
  color: #fff !important;
  padding: 2px 10px;
  border-radius: 7px;
}

#question_logo {
  cursor: pointer;
}

.gray-bg {
  background: #E8E8E8 !important;
}

.delete-confirm {
  position: fixed;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;

  .delete-confirm-inner {             
    width: 100%;
    max-width: 500px;
    background: #f5f6fd;
    padding: 16px 24px 24px;
    border-radius: 12px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.5);

    .delete-confirm-title {
      font-size: 24px;
      font-weight: 500;
    }
  }
}

.profile-img-header {
  width: 34px;
  height: 34px;
  border-radius: 50%;
}

.image-profile-page {
  max-width: 150px;
  max-height: 150px;
}

@media (max-width: 367px) {
  .header-left-btns {
    flex-direction: column-reverse !important;
  }
}

/* Guest Login Page CSS Start */

.guestlogin-wrapper {
  background: linear-gradient(180deg, $primary 0%, #1E2F56 100%);
  background-repeat: no-repeat;
  background-position: center bottom;
  min-height: 100vh;
  color: #fff;

  .guest-join-meeting {
    padding-top: 30px;
  }
}

.guestlogin-topbar {
  background: linear-gradient(180deg, $primary 0%, #1E2F56 100%);
  box-shadow: 0px 1px 2px #00000029;
  padding: 12px;
}

/* Guset Login Page CSS End */

// Guest User join CSS Start

.guestuser-joinwrap {
  .navbar {
    height: 70px;
    padding: 0;
  }
  .join-meeting-share-block{
    top: 88px;
  }
}

/* Custom Radio */
.other-user-option {
  display: flex;
  flex-direction: column;
}

.other-user-option label {
  color: #000;
  border-radius: 5px;
  padding: 14px 18px;
  background: #E9ECEF;
}

.other-user-option label:not(:first-child) {
  margin-top: 30px;
}

.other-user-option label:hover {
  background-color: #e9ecef;
  color: #000;
}

.other-user-option label.active,
.other-user-option label:focus,
.other-user-option label:hover {
  background-color: #191919;
  color: #fff;
}

.other-user-option [type="radio"]:checked,
.other-user-option [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.other-user-option [type="radio"]:checked+span,
.other-user-option [type="radio"]:not(:checked)+span {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
}

.other-user-option [type="radio"]:checked+span:before,
.other-user-option [type="radio"]:not(:checked)+span:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}

.other-user-option [type="radio"]:checked+span:after,
.other-user-option [type="radio"]:not(:checked)+span:after {
  content: '';
  width: 10px;
  height: 10px;
  background: $primary;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.other-user-option [type="radio"]:not(:checked)+span:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.other-user-option [type="radio"]:checked+span:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.custom-pagination {
  button {
    min-width: 36px;
  }

  &.gap-0-5 {
    gap: 2px;
  }
}

.join-meeting-share-block {
  .sponsor-detail {
    >.card-body {
      height: calc(100vh - 118px) !important;
      overflow: auto;
    }
  }

  @include media-breakpoint-down(xl) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: none;
    z-index: 10;

    .sponsor-detail {
      box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
    }
  }

  @include media-breakpoint-down(sm) {

    .font-24,
    .font-22 {
      font-size: 1.2rem;
    }

    .company-sponsor {
      width: 70px;
      height: 70px;
    }
  }
}

.share-meeting-show .join-meeting-share-block {
  display: block;
}

.close-share-meeting-mobile {
  display: none !important;
  position: absolute;
  top: 10px;
  right: 10px;

  @include media-breakpoint-down(xl) {
    display: block !important;
  }
}

#share-meeting .close-share-meeting-mobile {
  display: none !important;
}

// Guest User join CSS End

$theme-colors: map-merge($theme-colors, $custom-colors);

@mixin ellipsis($before: true) {
  content: "...";
  font-size: 1.25rem;
  padding: 0 3px;
  display: inline-block;

  @if($before) {
    margin-right: 2.5px;
  }

  @else {
    margin-left: 2.5px;
  }
}

.custom-pagination ul {
  list-style: none;
  display: flex;
  padding: 0;
  align-items: center;
  margin: 0;
  gap: 3px;
}

@media(max-width:767px) {
  .custom-pagination ul li {

    &:first-child,
    &.active,
    &.active-sibling:nth-last-child(2),
    // Show second to last child if the last one is active
    &:last-child {
      display: inline-block !important;
    }

    $how-many-on-ends: 3; // 1,2,3,...,10 || 1,...,8,9,10

    // There are >= 5 pages
    &:first-child:nth-last-child(n+6) {

      &~li {
        // Start out with all siblings hidden
        display: none;

        // Show the last children in the list by default
        &:nth-last-child(-n+#{$how-many-on-ends}) {
          display: inline-block;
        }

        // The child at the beginning of the last group shows ellipsis for the group
        &:nth-last-child(#{$how-many-on-ends}) {
          &:before {
            @include ellipsis(true);
          }
        }

        // The very beginning elements do not need to show ellipsis
        // The very end elements do not need to show ellipsis
      }

      &.active,
      &~li.active {

        // Show ellipsis before and after the active element
        &:before {
          @include ellipsis(true);
        }

        &:after {
          @include ellipsis(false);
        }

        // If the active element is in the first or last group, don't show ellipsis (siblings will take care of it)
        &:nth-child(-n+#{$how-many-on-ends - 1}),
        &:nth-last-child(-n+#{$how-many-on-ends - 1}) {

          &:before,
          &:after {
            display: none;
          }
        }

        // Hide the last group if "active" comes before them
        &~li:nth-last-child(-n+#{$how-many-on-ends}) {
          display: none;
        }

        // Show the first group together if "active" comes before them
        &~li:nth-child(-n+#{$how-many-on-ends}) {
          display: inline-block;
        }

        // If "active" is before the last member in the group, don't show ellipsis
        &~li:nth-child(-n+#{$how-many-on-ends - 1}) {
          &:after {
            display: none;
          }
        }


        // The child at the end of the first group shows ellipsis for the group
        &~li:nth-child(#{$how-many-on-ends}) {
          &:after {
            @include ellipsis(false);
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .custom-pagination ul li {

    &:first-child,
    &.active-sibling,
    &.active,
    &.active+li,
    &:last-child {
      display: inline-block !important;
    }

    // There are >= 7 pages
    &:first-child:nth-last-child(n+8) {
      $how-many-on-ends: 5; // 1,2,3,4,5,...,10 || 1,...6,7,8,9,10

      &~li {
        // Start out with all siblings hidden
        display: none;

        // Show ellipsis before the previous one
        &.active-sibling:before {
          @include ellipsis(true);
        }

        // Show ellipsis after the next one
        &.active+li:after {
          @include ellipsis(false);
        }

        // Show the last children in the list by default
        &:nth-last-child(-n+#{$how-many-on-ends}) {
          display: inline-block;
        }

        // The child at the beginning of the last group shows ellipsis for the group
        &:nth-last-child(#{$how-many-on-ends}) {
          &:before {
            @include ellipsis(true);
          }
        }

        // The very beginning elements do not need to show ellipsis
        &:nth-child(-n+#{$how-many-on-ends - 3}),
        // The very end elements do not need to show ellipsis
        &:nth-last-child(-n+#{$how-many-on-ends - 3}),
        // Even if it's a sibling to "active"
        &.active-sibling:nth-last-child(-n+#{$how-many-on-ends - 1}) {

          &:before,
          &:after {
            display: none !important;
          }
        }
      }

      &.active,
      &~li.active {

        // Hide the last group if "active" comes before them
        &~li:nth-last-child(-n+#{$how-many-on-ends}) {
          display: none;

          // If there is overlap, the element will show, but hide it's ellipsis
          &:before {
            display: none;
          }
        }

        // Show the first group together if "active" comes before them
        &~li:nth-child(-n+#{$how-many-on-ends}) {
          display: inline-block;
        }

        // If "active" is before the last member in the group, don't show ellipsis
        &~li:nth-child(-n+#{$how-many-on-ends - 1}) {
          &:after {
            display: none;
          }
        }


        // The child at the end of the first group shows ellipsis for the group
        &~li:nth-child(#{$how-many-on-ends}) {
          &:after {
            @include ellipsis(false);
          }
        }

        // "active" should never show ellipsis
        &:before,
        &:after {
          display: none;
        }
      }
    }
  }
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 1.5rem !important;
  line-height: 1.8rem !important;
  margin: 0.066rem !important;
}

.react-datepicker__month {
  margin: 0.1rem !important;
}

.signin-option iframe {
  margin: 0 auto !important;
}

.font-size-container {

  .btn {
    width: 25px;
    height: 25px;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px !important;
  }
}

#toggle-sidemenu {
  .fa-bars {
    font-size: 25px;

    @include media-breakpoint-down(md) {
      font-size: 25px;
    }
  }
}

#btn-end-mobile-meeting {
  display: none
}


.meeting-questioncard {
  padding: 10px;
  border-radius: 5px;
  background-color: #FFFFFF;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include media-breakpoint-down(md) {
    padding: 6px;
  }

  .meeting-user-badge {
    margin-left: 4px;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &.meeting-host,
  .meeting-host {
    background: rgba(124, 163, 255, 0.30) !important;

    .meeting-user-badge {
      position: relative;
      z-index: 1;

      &::after {
        position: absolute;
        content: "";
        width: calc(100% + 14px);
        height: calc(100% + 2px);
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        background-color: rgb(255 255 255);
        border-radius: 50px;
        z-index: -1;
        border: 1px solid #00000030;
      }

      .meeting-user-icon {
        &::before {
          content: "\f508" !important;
        }
      }
    }

  }

  &.unread {
    background: rgba(255, 201, 122, 0.5);

    &.meeting-host,
    .meeting-host {
      background: rgba(255, 201, 122, 0.5) !important;
    }
  }

  .question-reply {
    box-shadow: 0 0px 3px rgba(0, 0, 0, 0.3);
    padding: 8px;
    border-radius: 0.375rem;
  }
}

.custom-radio {
  position: relative;

  input[type="radio"] {
    appearance: none;


    &:checked {


      &+label {

        // &+.uncheck-btn {
        //   display: flex;
        // }

        background: #000;

        span {
          color: #fff;
        }

        &:before {
          border: 4px solid #fff;
          background-color: $primary;
        }
      }
    }
  }

  label {
    position: relative;
    display: block;
    padding: 14px 18px;
    border-radius: 5px;
    cursor: pointer;
    background: #E9ECEF;

    @media (hover: hover) {
      &:hover {
        background: #000;

        .label-text {
          color: #fff;
        }
      }
    }

    .label-text {
      padding-left: 28px;
      color: #000;
    }

    &:before {
      content: "";
      position: absolute;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      border: 1px solid #ddd;
      background: #fff;
      transform: translateY(-50%);
      top: 50%;
      transition: all 250ms ease-in-out
    }
  }
}

.btn-fake {
  cursor: none;
  pointer-events: none;
}

.flex-50 {
  flex: 50%
}

.meet-actions-wrapper {
  position: fixed;
  z-index: 1000;

  .meet-actions {
    box-shadow: 0 2px 30px rgba(0, 0, 0, 0.6);
    transition: 250ms box-shadow cubic-bezier(0.075, 0.82, 0.165, 1);

    &:hover {
      box-shadow: 0 2px 30px rgba(0, 0, 0, 0.7);
    }
  }
}

.drag-handle {
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

.dashboard-layout {
  background: url(../../public/images/dashboard-img.svg) center bottom no-repeat;
  background-size: contain;
}

.edited-label {
  font-size: 11px;
  color: #0e0e0e;
  position: absolute;
  bottom: 1px;
  right: 8px;
}

.badge-notification {
  font-size: 14px !important;
  line-height: 0.9 !important;
  padding: 4px 8px !important;
}

.password-toggle {
  position: relative;

  .password-toggle-btn {
    position: absolute;
    right: 10px;
    top: 15px;
  }
}

.size32 {
  width: 32px;
  height: 32px;
}

.file-drop {
  padding: 15px;

  *> {
    pointer-events: none;
  }

  .droppable {
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    border: 1px dashed #A1A1A1;
    border-radius: 5px;
    position: relative;
    min-height: 50px;
  }
}

.btn-outline-danger {

  &:hover,
  &:active,
  &.active {
    color: white !important;
  }
}

.z-1 {
  z-index: 1;
}

.signup-wrap {
  background: linear-gradient(90deg, #56ccf2, #56cbf293);
  min-height: 100vh;
}

.navbar .nav-item .nav-link {
  color: #000;
  font-size: 18px;
}

.signin-form .form-type {
  font-weight: 700;
  margin: 0;
  font-size: 25px;
}

.google-signin {
  border: 1px solid #ddd;
  color: #000;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;

  &:hover {
    background-color: #db4437;
    color: #fff;
  }

}

.txt-error {
  font-size: 14px;
}

/* Index Page CSS Start */

.wrapper .nav-link {
  color: #000;
  font-size: 18px;
}

.intro {
  background: url(../images/banner-img.webp) no-repeat 0 0;
  background-size: cover;
  background-attachment: fixed;
  object-fit: contain;
  padding: calc(100px + (210 - 150) * ((100vw - 100px) / (1900 - 300))) 0;
}

.intro .title {
  font-size: 46px;
  font-weight: 500;
  color: #fff;
}

@media (max-width:400px) {
  .intro .title {
    font-size: 40px;
  }
}

.intro .subtitle {
  font-size: 20px;
  color: #fff;
}

.section-info {
  padding: 50px 0;
}

.sliderImage {
  width: 100%;
  object-fit: cover;
  max-height: 480px;
}

.footer {
  background: linear-gradient(to right, #1482a0, #1cb5e0);
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.waiting-user-details {
  font-size: 18px;

  @include media-breakpoint-down(sm) {
    font-size: 13px;
  }
}

.selectedOptions-radio {
  cursor: pointer;
  transform: scale(1.5);
  margin-left: 4px;
}

.section-question-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .section-question-block {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .section-question-header {
      display: flex;
      justify-content: space-between;
      align-items: end;
      gap: 1rem;
    }

    .section-question {
      background-color: #3c5dab1c;
      border-radius: 6px;
      padding: 12px;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .section-option-block {
        display: flex;
        flex-direction: column;
        gap: 1rem;

      }
    }
  }
}

.poll-progress {
  background-color: #00000011 !important;
  margin-top: 6px;
}

.status-block {
  height: 300px;
  padding: 0.5rem;
  font-size: 24px;
  font-weight: 500;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  &-nodata {
    background-color: #dfdfdf;
  }

  &-started {
    background-color: rgba($color: $danger, $alpha: 0.2)
  }

  &-submitted {
    background-color: rgba($color: $success, $alpha: 0.2)
  }
}
.sticky-bottom, .sticky-top{
  -webkit-position: sticky; 
}

@import '~bootstrap/scss/bootstrap.scss';